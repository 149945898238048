@import "../../utils.scss";
@import "../../variables/colors";
@import "../../variables//fonts";
.contact-form {
  @include padding(60);
  max-width: 500px;
  margin: 0 auto;
  h2 {
    color: #fff;
  }
  p {
    color: #fff;
  }
  .demo-btn {
    min-width: 50%;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  &.demo {
    background-color: #fff;
    padding: 15px;
    .MuiGrid-container {
      // @include tablet {
      flex-wrap: nowrap;
      // }
    }

    h2 {
      color: #000;
      line-height: 1.07;
      font-size: 28px;
      margin-top: 0;
      font-family: $metro-polis-semi-bold !important;
    }
    p {
      color: #dc7100;
      font-size: 18px;
      font-family: $metro-polis-semi-bold !important;
    }
    @include mobile {
      padding: 15px 0;
      h2 {
        font-size: 20px;
        line-height: normal;
      }
      p {
        font-size: 16px;
        line-height: normal;
      }
    }
  }
}
form {
  width: 100%;
  text-align: left;
  max-height: 80%;
  overflow-y: auto;
  overflow-x: hidden;
  @include mobile {
    padding: 0 15px;
  }
}

input,
label,
textarea,
select {
  width: 100%;
  text-align: left;
}
.submit-btn {
  @include contact-btn;
  color: #000000;
  border-color: #000;
  text-align: center;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}
.alert-info {
  font-size: 1.5rem;
  color: #fff;
  text-align: center;
  @include mobile {
    font-size: 21px;
  }
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  &.white {
    color: #000;
  }
}
select {
  font-size: 16px;
  @include tablet {
    font-size: 1rem;
  }
}
.email-wrap {
  > div {
    flex: 1;
    &:first-child {
      margin-right: 1rem;
    }
  }
}

.mui-textfield--float-label > label {
  color: rgba(255, 255, 255, 0.5);
  font-family: $metro-polis-regular;
}
.mui-textfield > input,
.mui-textfield > textarea {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  font-family: $metro-polis-regular;
  color: #fff;
}
.mui-textfield--float-label > input:not(:focus).mui--is-not-empty ~ label,
.mui-textfield--float-label > textarea:not(:focus).mui--is-not-empty ~ label {
  color: rgba(255, 255, 255, 0.5);
}
.mui-textfield > textarea {
  resize: none;
}
.mui-textfield {
  @include mobile {
    margin-bottom: 10px;
    padding-top: 12px;
  }
}
.demo {
  .mui-textfield--float-label > label {
    color: rgba(0, 0, 0, 0.31);
    font-family: $metro-polis-regular;
  }
  .mui-textfield > input,
  .mui-textfield > textarea {
    border-bottom: 1px solid rgba(0, 0, 0, 0.31);
    font-family: $metro-polis-regular;
    color: #000;
  }
  .mui-textfield--float-label > input:not(:focus).mui--is-not-empty ~ label,
  .mui-textfield--float-label > textarea:not(:focus).mui--is-not-empty ~ label {
    color: rgba(0, 0, 0, 0.31);
  }
}
.MuiInputBase-input {
  @include mobile {
    font-size: 12px;
  }
}
.MuiFormLabel-root {
  font-size: 16px !important;
  font-family: $metro-polis-regular !important;
}
@import '../../utils.scss';
@import '../../variables/colors';

.header {
  @extend .flex;
  // box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
  position: fixed;
  width: 100%;
  z-index: 3;
  // color: $black-90;
  padding: .5rem 7vw;
  justify-content: space-between;
  color: black;
  // transition: all .3s ease;
  @include mobile {
    // background-color: $tealish;
    background-color: #fff;
    flex-direction: column;
    min-height: 50px;
    overflow: visible;
    justify-content: flex-start;
    padding: 0.9rem 7vw;  
  }
  @include tablet {
    height: 80px;
  }

  .logo {
    cursor: pointer;
    @include width(188);
    height: auto;
    align-self: center;
    @include mobile {
      height: 26px;
      width: auto;
      margin-top: 5px;
    }
  }

  ul {
    padding: 15px;
    margin: 0;
    display: flex;
    justify-content: space-between;
    @include mobile {
      flex-direction: column;
    }
    // width: 100%;

    li {
      list-style: none;
      display: flex;
      align-items: center;
      @include padding(0, 20);
      color: #000;
      cursor: pointer;
      text-transform: uppercase;
      @include font-size(21);
      .menu-item {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          display: inline-block;
          left: 10%;
          bottom: -5px;
          width: 80%;
          height: 2px;
          background-color: transparent;
        }
      }
      &.active {
        // color: white;
        span {
          &:before {
            background-color: #fff;
          }
        }
      }
      
      &:hover {
        // color: white;
        span {
          &:before {
            background-color: #fff;
          }
        }
      }

      @include mobile {
        padding: 1rem;
        font-size: 20px;
        text-align: center;
        justify-content: center;
      }

      a {
        text-decoration: none;
        color: inherit;
        @include font-size(21);

        &.active {
          color: white;
        }
      }
    }
  }

  &.scrolled {
    // background-color: #1dc7c2;
    background-color: #ffffffe3;
    box-shadow: 0 1px 1.5px 0 rgba(0,0,0,.24);
    color: white;
    height: 80px;
  }
}

.menu-icon {
  position: fixed;
  left: 10px;
  top: 15px;
  @include tablet {
    display: none;
  }
}

@import "./breakpoints";
@import "../utils.scss";

@include mobile {
  html {
    font-size: 8.5px;
  }
}

@include tablet {
  html {
    font-size: 14px;
  }
}

@include desktop {
  html {
    font-size: 15px;
  }
}

@include hd {
  html {
    font-size: 16px;
  }
}

@include hdPlus {
  html {
    font-size: 18px;
  }
}

@include fullHd {
  html {
    font-size: 20px;
  }
}

@include uHd {
  html {
    font-size: 25px;
  }
}

@font-face {
  font-family: "Metropolis-Medium";
  src: url("/Metropolis-Medium.otf");
}
@font-face {
  font-family: "Metropolis-SemiBold";
  src: url("/Metropolis-SemiBold.otf");
}
@font-face {
  font-family: "Metropolis-Bold";
  src: url("/Metropolis-Bold.otf");
}
@font-face {
  font-family: "Metropolis-Regular";
  src: url("/Metropolis-Regular.otf");
}
@font-face {
  font-family: "Metropolis-Light";
  src: url("/Metropolis-Light.otf");
}


$metro-polis-medium: "Metropolis-Medium", sans-serif;
$metro-polis-semi-bold: "Metropolis-SemiBold", sans-serif;
$metro-polis-bold: "Metropolis-Bold", sans-serif;
$metro-polis-regular: "Metropolis-Regular", sans-serif;
$metro-polis-light: "Metropolis-Light", sans-serif;

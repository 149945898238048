.count-btn-wrap {
  display: flex;
  height: 20px;
  border-radius: 1px;
  width: 60px;
  justify-content: center;
  align-items: flex-start;
  text-transform: uppercase;
}
.count {
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 5px 0;
}

.fav-icon {
  bottom: 10px;
  right: 15px;
  cursor: pointer;
  &.minus {
    right: auto;
    left: 15px;
  }
  &.delete {
    right: 15px;
    top: 15px;
    position: absolute;
  }
}

@import '../../utils.scss';

.modal-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(0,0,0,.5);
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.modal-content {
  position: relative;
  width: 80%;
  height: auto;
  max-height: 90%;
  overflow: auto;
  margin: auto;
  background: white;
  box-shadow: 0 5px 15px rgba(0,0,0,.5);
  border-radius: 8px;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include sMobile {
    width: 90%;
    padding: 0;
  }
  @include tablet {
      width: 50%;
      max-width: 500px;
  }
}

.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  @include mobile {
    right: 10px;
    top: 10px;
    width: 25px;
  }
}
.modal-title {
  font-size: 1.5rem;
  color: rgba(0,0,0,.87);
  @include mobile {
    font-size: 21px;
  }
}
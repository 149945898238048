@import "../../utils.scss";
@import "../../variables/colors";
@import "../../variables/fonts";
.dashboard {
    color: #000;
    h2 {
        font-family: $metro-polis-bold;
        color: $header-color;
        @include font-size(46);
        @include line-height(48);
        @include mobile {
            font-size: 22px;
            line-height: 23px;
            text-align: center;
            
        }
    }
    h3 {
        @include font-size(30);
        font-family: $metro-polis-regular;
        
    }
    p {
        @include line-height(27);
        font-family: $metro-polis-regular;
        @include mobile {
            font-size: 14px;
            line-height: 20px;
            text-align: center;
        }
    }
}

@mixin section-padding {
    @include padding(0, 75);
    @include mobile {
        padding: 23px 5%;
    }
}
.feature-main {
 display: flex;
 flex-flow: row wrap;

 div {
    width: 25%;
    padding: 30px;
    @include mobile {

        width: 100%
    }
}

 h3 {
        font-family: $metro-polis-bold;
        text-align: center;
        color: $header-color;

        @include font-size(35);
        @include line-height(38);
        @include mobile {
            font-size: 20px;
            line-height: 21px;
            text-align: center;
            
        }
    }


    p {
        @include line-height(27);
        font-family: $metro-polis-regular;
        @include mobile {
            font-size: 14px;
            line-height: 20px;
            text-align: center;
        }
    }

} 

.top-section {
    @include mobile {
        flex-direction: column-reverse;
        height: auto;
        padding-top: 7rem;
        margin-bottom: 35px;
    }
    @extend .flex;
    padding-top: 5rem;
    height: 48vw;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.25) 18%, #ffffff 81%);
    > div {
        flex: 1;
    }
    h1,
    h4 {
        text-align: right;
        margin: 1rem 0;
        @include mobile {
            font-size: 16px;
            line-height: 18px;
        }
    }
    h1 {
        margin: 0;
        @include font-size(53);
        font-family: $metro-polis-bold;
        @include mobile {
            font-size: 28px;
            line-height: 30px;
        }
    }
    h4 {
        @include font-size(24);
        @include mobile {
            font-size: 16px;
            line-height: 18px;
        }
    }
}

.banner-content {
    @extend .flex;
    @extend .flex-column;
    @extend .align-bottom;
    @extend .justify-center;
    h1 {
    color: $header-color;
    }
    @include mobile {
        align-items: center;
    }
}
.demo-btn {
    background-color: $button-color;
    @include height(46);
    @include min-width(210);
    @include font-size(17);
    @include border-radius(27);
    line-height: normal;
    outline: none;
    border: none;
    color: #fff;
    text-transform: uppercase;
    font-family: $metro-polis-semi-bold;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
        background-color: #b36716;
      }
      @include mobile {
          height: 36px;
          border-radius: 27px;
          font-size: 13px;
          line-height: 26px;
          padding:  0 15px;
          width: 165px;
          margin-top: 6px;
      }
}
.banner-image {
    padding: 2vw;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    > img {
        max-width: 80%;
        height: auto;
    }
    @include tablet {
        padding: 0 0 0 5rem;
        > img {
            max-width: 100%;
        }
    }
}
.section-2 {
    position: relative;
    @include section-padding;
    h2 {
        text-align: center;

    }
    p {
        @include text-center;
    }
    @include mobile {

    }
    
}
.qr {
    @include width(282);
    @include mobile {
        width: 196px;
        margin-top: 35px;
    }
}
.arrow-wrap {
    @include font-size(16);
    @include line-height(13);
    @include margin(77, 0);
    font-family: $metro-polis-semi-bold;
    position: relative;
    @include mobile {
        justify-content: center;
        display: flex;
        flex-direction: column;
        margin: 10px 0;
    }
}
.double-arroe-wrap {
    display: flex;
    align-items: flex-start;
    .arrow-wrap {
        margin: 10px;
    }
    @include tablet {
        flex-direction: column;
        .arrow-wrap {
            margin: 5rem 10px;
        }
    }

}

[class^="arrow-txt-"] {
    position: relative;
    @include mobile {
        position: initial;
        font-size: 14px;
        // line-height: .93;
        text-align: center;
    }
}
.arrow-txt-1 {
    left: 10%;
    bottom: 1rem;
}
.arrow-txt-2 {
    top: 1rem;
    left: 13%;
}
.arrow-txt-3 {
    bottom: 1.2rem;
}
.dine-img {
    @include margin(17.5);
    @include width(173);
    @include mobile {
        width: 120px;
    }
}
.app-img {
    @include mobile {
        width: 196px;
    }
}
.left-triangle {
    position: absolute;
    left: 0;
    top: -11rem;
    @include mobile {
        width: 55px;
        top: -5rem;
    }
}
.section-3 {
    @include section-padding;
    position: relative;
    .img-wrap {
        @include width(496);
        @include padding(0, 43, 0, 0);
        flex-shrink: 0;
        @include mobile {
            width: 100%;
            padding: 0;
            justify-content: center;
            align-items: flex-center;
            
        }

    }
    @include mobile {
        .align-top > div{
            text-align: center;
        }
    }
}
.right-triangle {
    position: absolute;
    right: 0;
    bottom: -23rem;
    @include mobile {
        width: 55px;
        bottom: -6rem;
    }
}
.section-4 {
    @include section-padding;
    > div {
        flex: 1;
    }
    .img-wrap {
        padding-top: 8rem;
        padding-left: 1rem;
        img {
            max-width: 100%;
        }
    }
}
.faq {
    background-color: $faq-bg;
    @include padding(22, 30, 22, 26);
    position: relative;
    @include margin(7.5,0);
}
.question {
    font-family: $metro-polis-semi-bold;
    @include line-height(22);
    color: #000;
    margin-bottom: .5rem;
    @include mobile {
        font-size: 14px;
        line-height: 18px;
    }
}
.answer {
    font-family: $metro-polis-regular;
    @include font-size(16);
    @include line-height(19);
    @include mobile {
        font-size: 13px;
        line-height: inherit;
    }
}
.faq-btn {
    position: absolute;
    right: 1.5rem;
    cursor: pointer;
}
.section-5 {
  background-color: $black-two;
}
.section-6 {
 @include padding(63, 50);
}
.footer-logo, .quick-menu {
    flex: 2;
}
.footer-logo {
    padding-right: 5rem;
    > img {
        @include height(40);
    }
    @include mobile {
        text-align: center;
        padding: 0;
    }
}
.quick-menu {
    padding: 0 1rem;
}
.address{
    flex: 1;
    @include tablet{
        text-align: right;
    }
}
.dashboard-footer {
    ul {
        margin: 0;
        padding: 0;
        flex: 1;
        li {
            font-family: $metro-polis-semi-bold;
            list-style-type: none;
            text-transform: uppercase;
            @include line-height(26);
            padding: 10px 0;
        }
    }
    a{
        color: #000;
    }
    h4 {
        margin-top: 0;
        font-family: $metro-polis-bold;
    }
    p {
        font-family: $metro-polis-medium;
    }
    .phone {
        margin: 1rem 0;
    }
    @include mobile {
        flex-direction: column;
    }
}
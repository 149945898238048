@import "./variables/fonts";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.model-open {
  overflow: hidden !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

* {
  box-sizing: border-box;
}

body {
  font-family: $metro-polis-medium;
  color: rgb(87, 89, 98);

  h3 {
    margin: 5px 0;
  }
}

.sm-header {
  height: 70px;
  display: flex;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  padding: 15px;
  margin-bottom: 10px;
  background: #fff;
  z-index: 2;
  width: 100%;

  .logo {
    max-height: calc(100% - 10px);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .search {
    position: absolute;
    right: 15px;
    @include sMobile {
      right: calc(50% - 235px);
    }
  }
  @include sMobile {
    > a {
      left: calc(50% - 235px);
      position: fixed;
    }
  }
}

h4 {
  font-weight: 400;
}

.dine-home {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto;
  position: relative;
}

.main-block {
  animation-name: move-main-block;
  animation-duration: .3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  height: calc(100vh - 60px);
}

.category-list-wrap {
  height: 137px;
  overflow: hidden;
  width: 100%;
  flex-shrink: 0;
}

.category-list {
  display: flex;
  overflow: auto;
  margin: 0 5px;
  flex-shrink: 0;
}

.category-item {
  margin: 0 3px;
  width: 120px;
  margin-bottom: 2px;

  img {
    border-radius: 6px;
    width: 120px;
    height: 80px;
    object-fit: cover;
  }

  > div {
    color: rgb(87, 88, 100);
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
  }

  &.active {
    border-bottom: 2px solid rgb(230, 3, 75);
  }
}

.item-list {
  width: 100%;
  padding: 0 15px;

  &.wishlist {
    max-height: calc(100vh - 225px);
    overflow: auto;
  }
}

.wishlist-wrap {
  font-family: $metro-polis-light;
  // height: calc(100vh - 30px);
  height: 100%;
  /* position: fixed; */
  padding: 0 0 30px;
  background-color: #fff;
  // margin: 0;
  /* bottom: 30px; */
  right: 0;
  width: 1px;
  animation: wishlist .3s ease-out;
  z-index: 1;
  text-align: center;
  // .item-list {
  // height: 85%;
  // overflow: auto;
  // padding-bottom: 50px;
  // }
  // @include tablet {
  max-width: 500px;
  right: auto;
  // }
  .mui-textfield > input,
  .mui-textfield > textarea,
  .mui-textfield--float-label > label {
    color: #555964;
  }

  .mui-textfield--float-label > input:not(:focus).mui--is-not-empty ~ label,
  .mui-textfield--float-label > textarea:not(:focus).mui--is-not-empty ~ label {
    color: #555964;
  }

  .mui-textfield > input,
  .mui-textfield > textarea {
    border-bottom: 1px solid rgba(0, 0, 0, 0.19);
  }
}
@keyframes wishlist {
  from {
    width: 0;
    height: 0;
  }

  to {
    width: 100%;
    height: 90vh;
  }
}

.animate {
  width: 100%;
}

.item {
  display: flex;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin: 5px 0;
  position: relative;
  border-radius: 6px;
  background-color: #fff;

  > img {
    border-radius: 6px 0 0 6px;
    height: 100px;
    width: 100px;
    max-width: 100px;
    max-height: 100px;
    flex-shrink: 0;
  }

  .item-desc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    padding: 10px;
    flex-grow: 1;

    h3 {
      margin: 5px 0;
      font-weight: 400;
      font-size: 15px;
      line-height: normal;
      display: flex;
      align-items: flex-start;
      max-width: calc(100% - 32px);

      img {
        width: auto;
        height: 12px;
        margin-right: 3px;
      }
    }
  }
}

#detail-block {
  position: relative;
  left: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation-name: move-block;
  animation-duration: .3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;

  > img {
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
    // max-height: 315px;
    height: 78.2vw;
    max-height: 390px;
  }
}

.back-btn {
  position: absolute;
  left: 15px;
  top: 15px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);

  &:hover {
    background-color: rgba(255, 255, 255, 0.6);
  }
}
@keyframes move-block {
  from {
    left: 100%;
  }

  to {
    left: 0;
  }
}
@keyframes move-main-block {
  from {
    right: 100%;
  }

  to {
    right: 0;
  }
}

.dine-block {
  width: 100%;
  position: relative;
  margin-top: 70px;
  @media only screen and (min-width: 768px) {
    // width: 40%;
  }
}

.image-block {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-shrink: 0;
  display: none;
  @media only screen and (max-width: 768px) {
    display: none;
  }
}

#list {
  margin-top: 25px;
  padding-bottom: 35px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  background-color: #fff;
  height: 30px;
  width: 100%;
  font-size: 12px;
  align-items: center;

  a > img {
    max-height: 13px;
    top: 2px;
    position: relative;
  }
}

.wishlist-icon {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  // background-color: #fff;
  // box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index: 2;

  &.close-icon {
    transition: all .3s ease-in;
    bottom: 80vh;
    display: none;
  }
  @include sMobile {
    right: calc(50% - 235px);
  }
}

.badge {
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -4px;
  right: -4px;
}

.loader {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  z-index: 4;
  left: 0;
  top: 0;

  > img {
    max-width: 200px;
    border-radius: 50%;
    position: absolute;
    left: calc(50% - 100px);
    top: calc(50% - 100px);
    animation: App-logo-spin infinite 5s linear;
  }

  &.p-absolute {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.details-wrap {
  padding: 15px 15px 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;

  .fav-icon {
    bottom: auto;
    top: 25px;
    // svg {
    //   width: 16px;
    //   height: 16px;
    // }
  }
}

.item-icons-wrap {
  display: flex;
  justify-content: space-around;

  > div {
    position: relative;
  }

  svg {
    margin: 0 5px;
    width: 36px;
    height: 36px;
  }
}

.tooltip {
  position: absolute;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 15px;
  border-radius: 6px;
  z-index: 1;
  transition: opacity .6s;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
}

.total-amount {
  height: 57px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.36);
  background-color: #ffffff;
  color: #000;
  font-size: 25px;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  align-items: center;
}

.total-tax {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-weight: bold;

  div {
    line-height: 1;
  }

  span {
    font-size: 10px;
  }
}

.place-order-btn {
  text-transform: uppercase;
  height: 57px;
  background-color: #db7428;
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: .5px;

  &.disabled {
    pointer-events: none;
    opacity: .5;
  }
}

.wishlist-success-txt {
  font-size: 24px;
  padding: 20px 15px;
}

.order-item {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.19);
  align-items: flex-end;
  font-size: 20px;

  > div:first-child {
    text-align: left;
  }

  &:first-child {
    border-top: none;
  }

  &:last-child {
    border-top: 2px solid rgba(0, 0, 0, 0.19);
    font-weight: 600;
  }
}

.order-again {
  background-color: #db7428;
  color: #fff;
  height: 57px;
  font-size: 24px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  margin: 25px auto;
  border-radius: 7px;
  text-transform: uppercase;
}

.qty-price {
  display: flex;
  align-items: center;

  .sm-txt {
    font-size: 16px;
    margin-right: 10px;
  }
}

.veg-only {
  // color: #b8b8b8;
  font-size: 16px;
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  padding: 22px 10px 0;
  text-transform: uppercase;

  span {
    position: absolute;
    height: 16px;
    width: 32px;
    background-color: #b8b8b8;
    border-radius: 8px;
    left: 9ch;

    &:before {
      content: "";
      position: absolute;
      left: 1px;
      top: 1px;
      right: auto;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      background-color: #fff;
      transition: all .2s ease-in;
    }

    &.active {
      &:before {
        left: 17px;
      }
    }
  }
}

.search-input {
  height: 53px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.19);
  background-color: #ffffff;
  border: none;
  padding: 0 15px 0 60px;
  outline: none;
  margin-top: 9px;
  flex-shrink: 0;
}

.search-page {
  padding: 0 15px;
  height: 100vh;
}

.hide {
  height: 0;
  overflow: hidden;
}

.add-btn-block {
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.fixed-to-bottom {
  position: fixed;
  bottom: 30px;
  left: 0;
  width: 100%;
  max-width: 500px;
  @include sMobile {
    left: calc(50vw - 250px);
  }
}

.note-chef {
  height: 75px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.19);
  outline: none;
  border: none;
  border: 1px solid #979797;
  border-radius: 8px;
  margin: 3px 0;
  resize: none;
  padding: 10px 15px;
  color: #555964;
}

.text-label {
  padding: 3px 15px;
  margin: 5px 0;
  text-align: left;
  color: #000;

  label {
    // max-width: 120px;
    line-height: 1.5;
    padding: 5px 0;
  }

  input {
    outline: none;
    border: 1px solid #979797;
    padding: 10px 5px;
    border-radius: 8px;
    margin: 3px 0;
  }
}

.check-icon {
  width: 75px;
  height: 75px;
}

.price-tag {
  position: absolute;
  bottom: 15px;
  right: 15px;
  display: flex;
  font-size: 20px;
  align-items: center;

  .small-txt {
    font-size: 14px;
  }
}

.no-result {
  display: flex;
  text-align: center;
  margin: 25px auto 0;
  justify-content: center;
  font-size: 20px;
  flex-direction: column;
}

.accordion-head {
  .MuiIconButton-root {
    .MuiSvgIcon-root {
      font-size: 2.5rem;
    }
  }

  .MuiAccordionDetails-root {
    padding: 8px 0 16px;
  }
}

.p-relative {
  position: relative;
}

.search-list-block {
  position: relative;
  height: 100%;
}

.float-input {
  margin-top: 15px !important;
  .MuiOutlinedInput-inputMultiline {
    font-size: 16px;
    line-height: 1.2;
  }

  legend {
    font-size: 14px;
  }

  textarea, input {
    color: #555964;
    font-family: $metro-polis-light;
    font-size: 16px;

  }

}

.description {
  margin: 10px;
}